var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-page"},[_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-content"},[_c('div',{staticClass:"hero-text-and-button"},[_vm._m(0),_c('div',{staticClass:"hero-button-container"},[_c('router-link',{staticClass:"cta-button hero-button",attrs:{"to":"/guest-dashboard"}},[_vm._v("Try it out as a guest")])],1)]),_vm._m(1)])]),_vm._m(2),_vm._m(3),_vm._m(4),_c('section',{staticClass:"cta"},[_vm._m(5),_c('div',{staticClass:"get-started-button-container"},[_c('router-link',{staticClass:"cta-button get-started-button",attrs:{"to":"/register"}},[_vm._v("Get Started")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-text"},[_c('h1',[_vm._v("Later Ladder")]),_c('p',{staticClass:"tagline"},[_vm._v("Enjoy footy on your schedule - spoiler free.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-image"},[_c('img',{staticClass:"laptop-mockup",attrs:{"src":require("@/assets/laptop-mockup-straight.svg"),"alt":"Later Ladder laptop mockup"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mission"},[_c('div',{staticClass:"mission-content"},[_c('h2',[_vm._v("Our Mission")]),_c('div',{staticClass:"mission-text-box"},[_c('p',[_vm._v("Our mission at Later Ladder is straightforward: to offer a spoiler-free experience for AFL fans. We believe that every fan deserves to enjoy the game's outcomes and the evolving team standings without the risk of spoilers. Our solution is a personalized, spoiler-free zone for AFL content, tailored to your viewing schedule.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"features"},[_c('h2',[_vm._v("Features")]),_c('div',{staticClass:"feature-cards"},[_c('div',{staticClass:"feature-card"},[_c('img',{staticClass:"feature-icon",attrs:{"src":require("@/assets/feature1.svg"),"alt":"Vector illustration of a human figure responding to an alert issued by a mobile device"}}),_c('div',{staticClass:"feature-text"},[_c('h3',[_vm._v("Control What You See")]),_c('p',[_vm._v("Mark games as watched and see team standings update accordingly. You're in control, free from worrying about spoilers for the games you haven't caught up on yet.")])])]),_c('div',{staticClass:"feature-card"},[_c('img',{staticClass:"feature-icon",attrs:{"src":require("@/assets/feature2.svg"),"alt":"Vector illustration of a human figure selecting from presented options"}}),_c('div',{staticClass:"feature-text"},[_c('h3',[_vm._v("Personalize Your AFL Experience")]),_c('p',[_vm._v("With a simple account setup, your watched games list and the corresponding ladder updates are saved and customized to your viewing journey.")])])]),_c('div',{staticClass:"feature-card"},[_c('img',{staticClass:"feature-icon",attrs:{"src":require("@/assets/feature3.svg"),"alt":"Vector illustration of a human figure sitting by a large clock, adjusting the time displayed by the clockhands"}}),_c('div',{staticClass:"feature-text"},[_c('h3',[_vm._v("Enjoy AFL on Your Terms")]),_c('p',[_vm._v("Our app is built for ease and efficiency, focusing on delivering the information you want, precisely when you want it.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-us-container"},[_c('section',{staticClass:"why-us"},[_c('h2',{staticClass:"why-us-header"},[_vm._v("Why Choose Later Ladder?")]),_c('div',{staticClass:"phone-mockup"},[_c('img',{attrs:{"src":require("@/assets/phone-mockup-angled.svg"),"alt":"Later Ladder phone mockup"}})]),_c('div',{staticClass:"why-us-content"},[_c('p',[_vm._v("Later Ladder was created from a genuine passion for AFL and an understanding of the international fan's dilemma. We bridge the gap between your love for AFL and the practicality of your personal schedule. Our app ensures that you never have to compromise on the excitement of the game due to spoilers again.")]),_c('p',[_vm._v("At Later Ladder, we're not just another app; we're your essential tool for enjoying AFL games on your schedule, keeping the spirit of competition alive and thrilling. Dive into your games, manage your standings, and enjoy AFL like never before, all through a straightforward, user-friendly platform. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cta-text"},[_c('h2',[_vm._v("Embrace each match at your pace, without spoilers.")])])
}]

export { render, staticRenderFns }